import axios from "axios";
// import TokenService from "./TokenService"; // Assurez-vous d'importer le TokenService
import authService from "../service/auth";

// const baseURL = process.env.REACT_APP_BASE_URL;
// url Demo
const baseURL = "https://api.demo.recrutech.pro/";
// url Prod
// const baseURL = "https://api.recrutech.pro/";

const instance = axios.create({
  baseURL,
  withCredentials: false,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Content-Type": "application/json",
  },
});

// Ajouter un interceptor de requête
instance.interceptors.request.use(
  (config) => {
    // Exemple : Ajouter un token d'authentification à chaque requête
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Gérer les erreurs de requête
    return Promise.reject(error);
  }
);

// Ajouter un interceptor de réponse
instance.interceptors.response.use(
  (response) => {
    // console.log("ddd", response);
    return response;
  },
  (error) => {
    // Exemple : Gérer les erreurs globales comme un 401
    if (error.response && error.response.status === 401) {
      // Rediriger l'utilisateur vers la page de connexion
      console.log("Erreur 401 détectée : déconnexion automatique : ", error);
      // Supprimer le token et déconnecter l'utilisateur
      authService.signOut();
      window.location.href = "/";
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default instance;
